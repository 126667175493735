<template>
    <div class="card mb-4" style="box-shadow: none !important;">
        <div style="display: flex;" class="card-header pb-0">
            <soft-input style="border-radius: 4rem !important; padding-top: 2px; width: 12rem" id="referral" type="text"
                placeholder="Transaction Type Filter" name="select" />
            <div style="margin-left: 10px"></div>
            <soft-input style="border-radius: 4rem !important; padding-top: 2px; width: 12rem;" id="referral"
                type="text" placeholder="Period Filter" name="select" />
            <div style="margin-left: 10px"></div>
            <!-- <select class="form-control form-control-sm">
                <option>Transaction Type Filter</option>
            </select> -->
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Transaction Type
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Reference Number
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Date
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                Amount
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Deposit</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">DEP-4MF6J0</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">23-May-2022</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <span style="color: #05D889" class="me-2 text-xs font-weight-bold">KES
                                        1,300.50</span>
                                </div>
                            </td>
                            <td class="align-middle">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Loan Repayment</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">DEP-4MF6J0</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">20-May-2022</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <span class="me-2 text-xs font-weight-bold">KES 5,987.10</span>
                                </div>
                            </td>
                            <td class="align-middle">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Withdrawal</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">DEP-4MF6J0</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">10-April-2022</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <span style="color: #FF0C0C" class="me-2 text-xs font-weight-bold">KES
                                        3,150.00</span>
                                </div>
                            </td>
                            <td class="align-middle">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Deposit</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">DEP-4MF6J0</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">01-April-2023</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <span style="color: #05D889" class="me-2 text-xs font-weight-bold">KES
                                        2,000.00</span>
                                </div>
                            </td>
                            <td class="align-middle">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// import SoftProgress from "@/components/SoftProgress";
import SoftInput from "@/components/SoftInput.vue";

export default {
    name: "transactions-table",
    components: {
        SoftInput
        // SoftProgress,
    },
    dat() {
        return {
            showMenu: false,
        };
    }
};
</script>
