import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Savings from "@/views/Savings.vue";
import Loans from "@/views/Loans.vue";
import Investments from "@/views/Investments.vue";
import Packages from "@/views/Packages.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import Profile from "@/views/Profile.vue";
import SignIn from "@/views/SignIn.vue";
import ForgotPin from "@/views/ForgotPin.vue";
import SignUp from "@/views/SignUp.vue";
import Personal from "@/views/Personal.vue";
import Survey from "@/views/Survey.vue";
import Tips from "@/views/Tips.vue";
import TipTwo from "@/views/TipTwo.vue";
import TipThree from "@/views/TipThree.vue";
import TipFour from "@/views/TipFour.vue";
import TipFive from "@/views/TipFive.vue";
import Product from "@/views/Product.vue";
import SavingsExplainer from "@/views/savings/Explainer.vue";

//BYOB Onboarding
import CreateBYOB from "@/views/savings/CreateBYOB.vue";
import BYOBTarget from "@/views/savings/BYOBTarget.vue";
import BYOBDate from "@/views/savings/BYOBDate.vue";
import BYOBPeriod from "@/views/savings/BYOBPeriod.vue";
import BYOBInterest from "@/views/savings/BYOBInterest.vue";

//Custom Goal Onboarding
import CreateCustom from "@/views/savings/CreateCustom.vue";
import CustomTarget from "@/views/savings/CustomTarget.vue";
import CustomDate from "@/views/savings/CustomDate.vue";
import CustomPeriod from "@/views/savings/CustomPeriod.vue";
import CustomInterest from "@/views/savings/CustomInterest.vue";

//Investments Onboarding
import InvestmentExplainer from "@/views/investments/Explainer.vue";
import Age from "@/views/investments/Age.vue";
import What from "@/views/investments/What.vue";
import Income from "@/views/investments/Income.vue";

//Borrowing Onboarding
import BorrowingExplainer from "@/views/borrowing/Explainer.vue";
import BorrowerInformation from "@/views/borrowing/Personal.vue";
import Amount from "@/views/borrowing/Amount.vue";
import ListedLenders from "@/views/borrowing/ListedLenders.vue";
import Guarantors from "@/views/borrowing/Guarantors.vue";
import LoanTips from "@/views/borrowing/LoanTips.vue";

//Lending Onboarding
import LendingExplainer from "@/views/lending/Explainer.vue";
import LendingBYOB from "@/views/lending/CreateBYOB.vue";
import BYOBDeposit from "@/views/lending/BYOBDeposit.vue";
// import ListedLenders from "@/views/borrowing/ListedLenders.vue";
// import Guarantors from "@/views/borrowing/Guarantors.vue";
// import LoanTips from "@/views/borrowing/LoanTips.vue";

//Loans Dashboard
import Borrower from "@/views/loansdash/Borrower.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/forgot-pin",
    name: "Forgot Pin",
    component: ForgotPin,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/savings",
    name: "Savings",
    component: Savings,
  },
  {
    path: "/loans",
    name: "Loans",
    component: Loans,
  },
  {
    path: "/investments",
    name: "Investments",
    component: Investments,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/info",
    name: "Personal Information",
    component: Personal,
  },
  {
    path: "/survey",
    name: "Quick Survey",
    component: Survey,
  },
  {
    path: "/tips",
    name: "Tips",
    component: Tips,
  },
  {
    path: "/tips/2",
    name: "Tip Two",
    component: TipTwo,
  },
  {
    path: "/tips/3",
    name: "Tip Three",
    component: TipThree,
  },
  {
    path: "/tips/4",
    name: "Tip Four",
    component: TipFour,
  },
  {
    path: "/tips/5",
    name: "Tip Five",
    component: TipFive,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/save/intro",
    name: "Savings Explainer",
    component: SavingsExplainer,
  },
  {
    path: "/save/byob",
    name: "Create BYOB",
    component: CreateBYOB,
  },
  {
    path: "/save/byob/1",
    name: "BYOB Target",
    component: BYOBTarget,
  },
  {
    path: "/save/byob/2",
    name: "BYOB Date",
    component: BYOBDate,
  },
  {
    path: "/save/byob/3",
    name: "BYOB Period",
    component: BYOBPeriod,
  },
  {
    path: "/save/byob/4",
    name: "BYOB Interest",
    component: BYOBInterest,
  },
  {
    path: "/save/custom",
    name: "Create Custom",
    component: CreateCustom,
  },
  {
    path: "/save/custom/1",
    name: "Custom Target",
    component: CustomTarget,
  },
  {
    path: "/save/custom/2",
    name: "Custom Date",
    component: CustomDate,
  },
  {
    path: "/save/custom/3",
    name: "Custom Period",
    component: CustomPeriod,
  },
  {
    path: "/save/custom/4",
    name: "Custom Interest",
    component: CustomInterest,
  },
  {
    path: "/invest/intro",
    name: "Investment Explainer",
    component: InvestmentExplainer,
  },
  {
    path: "/invest/1",
    name: "Investment Age",
    component: Age,
  },
  {
    path: "/invest/2",
    name: "Investment What",
    component: What,
  },
  {
    path: "/invest/3",
    name: "Investment Income",
    component: Income,
  },
  {
    path: "/loans/borrow",
    name: "Loan Borrower",
    component: Borrower,
  },
  {
    path: "/packages",
    name: "Sortika Packages",
    component: Packages,
  },
  {
    path: "/borrow/intro",
    name: "Borrowing Explainer",
    component: BorrowingExplainer,
  },
  {
    path: "/borrow/1",
    name: "Borrower Information",
    component: BorrowerInformation,
  },
  {
    path: "/borrow/2",
    name: "Loan Amount",
    component: Amount,
  },
  {
    path: "/borrow/3",
    name: "Listed Lenders",
    component: ListedLenders,
  },
  {
    path: "/borrow/4",
    name: "Guarantors",
    component: Guarantors,
  },
  {
    path: "/borrow/5",
    name: "Loan Tips",
    component: LoanTips,
  },
  {
    path: "/lend/intro",
    name: "Lending Explainer",
    component: LendingExplainer,
  },
  {
    path: "/lend/1",
    name: "Lending BYOB",
    component: LendingBYOB,
  },
  {
    path: "/lend/2",
    name: "BYOB Deposit",
    component: BYOBDeposit,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
