<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <h5>Loan Stats</h5>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Borrow from savings" value="KES 7,500" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Borrow from other" value="KES 25,100" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Borrow from group" value="KES 25,000" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Credit Score" value="87" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
        </div>
        <div style="margin-top: 10px; margin-bottom: 20px" class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8"
            id="nav">
            <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <li class="nav-item">
                    <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" id="activeLoan" href="#"
                        role="tab" aria-controls="ActiveLoan" aria-selected="true"
                        v-on:click="makeActive('activeLoanActive')">
                        Active
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="overdueLoan" role="tab"
                        aria-controls="OverdueLoan" v-on:click="makeActive('overdueLoan')" aria-selected="false">
                        Overdue
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="completeLoan" role="tab"
                        aria-controls="CompleteLoan" v-on:click="makeActive('completeLoanActive')"
                        aria-selected="false">
                        Complete
                    </a>
                </li>
            </ul>
        </div>
        <div>
            <active-loans-table />
        </div>
        <div style="margin-bottom: 3rem;"></div>
        <div class="row mt-4">
            <div class="col-md-6">
                <lenders-card title="Lenders Information" :lenders="[
                    {
                        name: 'Oliver Liam',
                        loanLimit: 'KES 11,740.00',
                        averageRate: '7%',
                        averageTime: '30 Minutes',
                    },
                    {
                        name: 'Charlie Harper',
                        loanLimit: 'KES 22,040.00',
                        averageRate: '10%',
                        averageTime: '30 Minutes',
                    },
                    {
                        name: 'Allan Harper',
                        loanLimit: 'KES 10,100.00',
                        averageRate: '8.5%',
                        averageTime: '30 Minutes',
                    },
                ]" />
            </div>
            <div class="col-md-6">
                <loan-request-table />
            </div>
        </div>
    </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import NavPillLoan from "../components/NavPillLoan.vue";
// import NavPillLoanStatus from "../components/NavPillLoanStatus.vue";
import setTooltip from "@/assets/js/tooltip.js";
import LendersCard from "../components/LendersCard.vue";
// import AllGoalsCard from "./components/AllGoalsCard.vue";
import ActiveLoansTable from "../components/ActiveLoansTable.vue";
import LoanRequestTable from "../components/LoanRequestTable.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
// import US from "../assets/img/icons/flags/US.png";
// import DE from "../assets/img/icons/flags/DE.png";
// import GB from "../assets/img/icons/flags/GB.png";
// import BR from "../assets/img/icons/flags/BR.png";
import {
    faHandPointer,
    faUsers,
    faCreditCard,
    faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
    name: "borrower-default",
    data() {
        return {
            iconBackground: "bg-gradient-success",
            faCreditCard,
            faScrewdriverWrench,
            faUsers,
            faHandPointer,
        };
    },
    components: {
        MiniStatisticsCard,
        // NavPillLoan,
        // NavPillLoanStatus,
        ActiveLoansTable,
        LendersCard,
        LoanRequestTable,
    },
    mounted() {
        setTooltip();
    },
};
</script>
