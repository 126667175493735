<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Total Savings" value="KES 23,000" :percentage="{
          value: '+505%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Savings Growth" value="5%" :percentage="{
          value: '+3%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="" value="" :percentage="{
          value: '-2%',
          color: 'text-danger',
        }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card title="" value="" :percentage="{
          value: '+5%',
          color: 'text-success',
        }" direction-reverse />
      </div>
    </div>
    <div style="display: flex; justify-content:space-between;">
      <div style="margin-top: 10px" class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8" id="nav">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" id="allGoals" href="#" role="tab"
              aria-controls="AllGoals" aria-selected="true" v-on:click="makeActive('allGoalsActive')">
              All Goals
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="fixedGoals" role="tab"
              aria-controls="FixedGoals" v-on:click="makeActive('fixedGoalsActive')" aria-selected="false">
              Fixed
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="premiumGoals" role="tab"
              aria-controls="PremiumGoals" v-on:click="makeActive('premiumGoalsActive')" aria-selected="false">
              Premium
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="customGoals" role="tab"
              aria-controls="CustomGoals" v-on:click="makeActive('customGoalsActive')" aria-selected="false">
              Custom
            </a>
          </li>
        </ul>
      </div>
      <div style="margin-top: 0.5rem;">
        <soft-button class="mb-2" variant="gradient" color="dark">Create A Goal
        </soft-button>
      </div>
    </div>
    <div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem;" id="allGoals"
        v-show="isActiveTab('allGoalsActive')">
        <all-goals />
      </div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="fixedGoals"
        v-show="isActiveTab('fixedGoalsActive')">
        <fixed-goals />
      </div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="premiumGoals"
        v-show="isActiveTab('premiumGoalsActive')">
        <premium-goals />
      </div>
      <div style="margin-left: -1.2rem !important; margin-right: -1.2rem" id="customGoals"
        v-show="isActiveTab('customGoalsActive')">
        <custom-goals />
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import AllGoals from "./goals/AllGoals.vue";
import FixedGoals from "./goals/FixedGoals.vue";
import PremiumGoals from "./goals/PremiumGoals.vue";
import CustomGoals from "./goals/CustomGoals.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import SoftButton from "@/components/SoftButton.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "savings-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      choice: 'allGoalsActive', // Borrow is chosen by default
    };
  },
  methods: {
    makeActive: function (val) {
      this.choice = val;
    },
    isActiveTab: function (val) {
      return this.choice === val;
    }
  },
  components: {
    MiniStatisticsCard,
    SoftButton,
    AllGoals,
    FixedGoals,
    PremiumGoals,
    CustomGoals,
  },
  mounted() {
    setTooltip();
    setNavPills();
  },
};
</script>
