<template>
    <div style="margin-top: 1.5rem;" class="py-4 container-fluid">
        <div class="row">
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Current balance" value="KES 53,000" :percentage="{
                    value: '+505%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Available balance" value="KES 53,000" :percentage="{
                    value: '+505%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="No. Of Referrals" value="10" :percentage="{
                    value: '+505%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Referral Balance" value="KES 230" :percentage="{
                    value: '+505%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
        </div>
        <div class="col-md-12 mb-4">
            <payment-methods-card />
        </div>
        <div class="mt-3 row">
            <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <profile-info-card title="Personal Information" :info="{
                    fullName: 'John Doe',
                    mobile: '(254) 722 123 123',
                    email: 'john.doe254@gmail.com',
                    dob: '12 December 1990',
                    identityNo: '21879876',
                    nationality: 'Kenyan',
                    location: 'Sports Club, Kasarani, Nairobi, Kenya',
                    mailingAddress: '43286 - 00100 GPO',
                }" :social="[
    {
        link: 'https://www.facebook.com/',
        icon: faFacebook,
    },
    {
        link: 'https://twitter.com/',
        icon: faTwitter,
    },
    {
        link: 'https://www.instagram.com/',
        icon: faInstagram,
    },
]" :action="{
    route: 'javascript:;',
    tooltip: 'Edit Profile',
}" />
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="card h-100">
                    <div class="p-3 pb-0 card-header">
                        <h6 class="mb-0">Platform Settings</h6>
                    </div>
                    <div class="p-3 card-body">
                        <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
                            Application
                        </h6>
                        <ul class="list-group">
                            <li class="px-0 border-0 list-group-item">
                                <soft-switch id="flexSwitchCheckDefault3" name="Project Launch" class="ps-0 ms-auto"
                                    label-class="mb-0 text-body ms-3 text-truncate w-80">Savings STK Push Opt-in or
                                    Opt-out</soft-switch>
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                <soft-switch id="flexSwitchCheckDefault4" name="Product Update" class="ps-0 ms-auto"
                                    label-class="mb-0 text-body ms-3 text-truncate w-80" checked>Savings
                                    Auto-deduct</soft-switch>
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                <soft-switch id="flexSwitchCheckDefault2" name="Email"
                                    label-class="mb-0 text-body ms-3 text-truncate w-80" class="ps-0 ms-auto"
                                    checked>Browser
                                    Notifications</soft-switch>
                            </li>
                        </ul>
                        <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">
                            Payment
                        </h6>
                        <ul class="list-group">
                            <li class="px-0 border-0 list-group-item">
                                <label>Payment method(deposit)</label>
                                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault"
                                    class="font-weight-light-survey">
                                    M-PESA
                                </soft-checkbox>
                                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault"
                                    class="font-weight-light-survey">
                                    Card
                                </soft-checkbox>
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                <label>Passive savings payment method</label>
                                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault"
                                    class="font-weight-light-survey">
                                    M-PESA
                                </soft-checkbox>
                                <soft-checkbox id="flexCheckDefault" name="flexCheckDefault"
                                    class="font-weight-light-survey">
                                    Card
                                </soft-checkbox>
                            </li>
                            <li class="px-0 border-0 list-group-item">
                                <label style="margin-bottom: 1rem;">Passive savings percentage (%)</label>
                                <soft-input id="text" type="number" placeholder="Eg. 5%" aria-label="Location" />
                                <soft-button style="margin-right: 1rem;" class="my-4 mb-2" variant="gradient"
                                    color="dark" fullWidth>
                                    Update Savings Percentage
                                </soft-button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-4 col-12 col-xl-4 mt-xl-0">
                <div class="card h-100">
                    <div class="p-3 pb-0 card-header">
                        <h6 class="mb-0">Referrals</h6>
                    </div>
                    <div style="padding-left: 20px; padding-right: 20px">
                        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                            <div class="d-flex flex-column">
                                <h6 style="font-size: 12px !important; color: grey !important"
                                    class="mb-1 text-dark font-weight-bold text-sm">
                                    Copy your referral link and share with your friends on your social channel
                                </h6>
                            </div>
                        </li>
                    </div>
                    <div style="padding-left: 10px; padding-right: 0px; display: flex; margin-bottom: 1rem;">
                        <input class="form-control form-control-sm"
                            style="padding: 0.5rem !important; border-radius: 12px" id="referral" type="text"
                            placeholder="sortikadl.page.link/Zwert" name="referralLink" disabled />
                        <div style="margin-left: 10px"></div>
                        <router-link :to="{ name: 'Sign Up' }" style="width: 50%">
                            <soft-button style="padding-left: 20px; padding-right: 20px; box-shadow:none" class="mb-2"
                                variant="gradient" color="success">Copy
                                Link
                            </soft-button>
                        </router-link>
                    </div>
                    <div class="p-3 pb-0 card-header">
                        <h6 class="mb-0">Active Referrals</h6>
                    </div>
                    <div class="p-3 card-body">
                        <ul class="list-group">
                            <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                                <soft-avatar class="me-3" :img="sophie" alt="kal" border-radius="xl" shadow="regular" />
                                <div class="d-flex align-items-start flex-column justify-content-center">
                                    <h6 class="mb-0 text-sm">Sophie B.</h6>
                                    <p class="mb-0 text-xs">0721098098</p>
                                </div>
                                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto">KES 20.00</a>
                            </li>
                            <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                                <soft-avatar class="me-3" :img="bruce" alt="kal" border-radius="xl" shadow="regular" />
                                <div class="d-flex align-items-start flex-column justify-content-center">
                                    <h6 class="mb-0 text-sm">Bruce Mark</h6>
                                    <p class="mb-0 text-xs">0721098098</p>
                                </div>
                                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto">KES 30.00</a>
                            </li>
                            <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                                <soft-avatar class="me-3" :img="team2" alt="kal" border-radius="xl" shadow="regular" />
                                <div class="d-flex align-items-start flex-column justify-content-center">
                                    <h6 class="mb-0 text-sm">Michael Rwamba</h6>
                                    <p class="mb-0 text-xs">0721098098</p>
                                </div>
                                <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto">KES 10.00</a>
                            </li>
                        </ul>
                        <div style="display: flex; justify-content: center;">
                            <soft-button class="my-4 mb-2" variant="gradient" color="dark" fullWidth>
                                Withdraw Referral Balance
                            </soft-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import ProfileInfoCard from "./ProfileInfoCard.vue";
import PaymentMethodsCard from "./PaymentMethodsCard.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import bruce from "@/assets/img/bruce-mars.jpg"
import team2 from "@/assets/img/team-2.jpg";
import SoftButton from "@/components/SoftButton.vue";
import {
    faFacebook,
    faTwitter,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import setTooltip from "@/assets/js/tooltip.js";

export default {
    name: "ProfileOverview",
    components: {
        SoftSwitch,
        ProfileInfoCard,
        SoftAvatar,
        SoftButton,
        SoftCheckbox,
        SoftInput,
        // DefaultProjectCard,
        // PlaceHolderCard,
        MiniStatisticsCard,
        PaymentMethodsCard,
    },
    data() {
        return {
            showMenu: false,
            sophie,
            bruce,
            team2,
            faFacebook,
            faTwitter,
            faInstagram,
        };
    },
    mounted() {
        this.$store.state.isAbsolute = true;
        setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
        this.$store.state.isAbsolute = false;
    },
};

</script>