<template>
    <div>
        <div class="card-header pb-0 px-3">
            <h6 class="mb-0">{{ title }}</h6>
        </div>
        <div style="padding: 0 !important; padding-top: 1rem; padding-bottom: 1rem;" class="card-body pt-4 p-3">
            <ul class="list-group">
                <li v-for="({ name, averageRate, }, index) of lenders" :key="index"
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-3 text-sm">{{ name }}</h6>
                        <span class="mb-2 text-xs">
                            Average Lending Rate:
                            <span class="text-dark ms-sm-2 font-weight-bold">
                                {{ averageRate }}</span>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";
export default {
    name: "ListedCard",
    props: {
        title: {
            type: String,
            default: "",
        },
        lenders: {
            type: Array,
            name: String,
            loanLimit: String,
            averageRate: String,
            averageTime: String,
            default: () => [],
        },
    },
    components: {
        // SoftButton,
    },
};
</script>
