<template>
    <div class="card">
        <div class="card-header pb-0 px-3">
            <h6 class="mb-0">{{ title }}</h6>
        </div>
        <div class="card-body pt-4 p-3">
            <ul class="list-group">
                <li v-for="({ name, loanLimit, averageRate, averageTime }, index) of lenders" :key="index"
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-3 text-sm">{{ name }}</h6>
                        <span class="mb-2 text-xs">
                            Loan Limit:
                            <span class="text-dark font-weight-bold ms-sm-2">
                                {{ loanLimit }}</span>
                        </span>
                        <span class="mb-2 text-xs">
                            Average Lending Rate:
                            <span class="text-dark ms-sm-2 font-weight-bold">
                                {{ averageRate }}</span>
                        </span>
                        <span class="text-xs">
                            Average Time to Reply:
                            <span class="text-dark ms-sm-2 font-weight-bold">{{ averageTime }}</span>
                        </span>
                    </div>
                    <div class="ms-auto text-end">
                        <soft-button class="mb-2" variant="gradient" color="success" full-width>Borrow
                        </soft-button>
                        <!-- <a class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;">
                            <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
                        </a>
                        <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;">
                            <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                        </a> -->
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
export default {
    name: "LendersCard",
    props: {
        title: {
            type: String,
            default: "",
        },
        lenders: {
            type: Array,
            name: String,
            loanLimit: String,
            averageRate: String,
            averageTime: String,
            default: () => [],
        },
    },
    components: {
        SoftButton,
    },
};
</script>
