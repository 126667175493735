<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <h5>Loan Stats</h5>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Total Loans" value="KES 7,500" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Actual Earnings" value="KES 25,100" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Loans Issued" value="10" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div style="display:flex; justify-content: flex-end;" class="col-xl-3 col-sm-6 mb-xl-0">
                <h6>Activate Lending</h6>
                <div style="margin-left: 0.5rem;"></div>
                <soft-switch id="lendingActive" name="lendingActive" checked>
                </soft-switch>
            </div>
        </div>
        <div style="margin-top: 10px; margin-bottom: 20px" class="row align-items-center">
            <div class="col-lg-4 col-sm-8">
                <nav-pill-loan-status />
            </div>
        </div>
        <div>
            <active-lender-table />
        </div>
        <div style="margin-bottom: 3rem;"></div>
        <div class="row mt-4">
            <div>
                <lender-request-table />
            </div>
        </div>
    </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import NavPillLoan from "../components/NavPillLoan.vue";
import NavPillLoanStatus from "../components/NavPillLoanStatus.vue";
import setTooltip from "@/assets/js/tooltip.js";
// import LendersCard from "./components/LendersCard.vue";
// import AllGoalsCard from "./components/AllGoalsCard.vue";
import ActiveLenderTable from "../components/ActiveLenderTable.vue";
import LenderRequestTable from "../components/LenderRequestTable.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
    faHandPointer,
    faUsers,
    faCreditCard,
    faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
    name: "lender-default",
    data() {
        return {
            iconBackground: "bg-gradient-success",
            faCreditCard,
            faScrewdriverWrench,
            faUsers,
            faHandPointer,
        };
    },
    components: {
        MiniStatisticsCard,
        // NavPillLoan,
        NavPillLoanStatus,
        ActiveLenderTable,
        // LendersCard,
        LenderRequestTable,
        SoftSwitch,
    },
    mounted() {
        setTooltip();
    },
};
</script>
