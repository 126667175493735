<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your phone number and pin to continue</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <label>Phone Number</label>
                    <div style="display: flex;">
                      <!-- <soft-input id="code" type="number" placeholder="+254" name="code" style="width: 25%" /> -->
                      <div style="width: 35%">
                        <select id="code" class="form-control form-control-sm" name="code">
                          <option>Country Code</option>
                          <option>+254</option>
                          <option>+255</option>
                          <option>+256</option>
                        </select>
                      </div>
                      <div style="margin-left: 10px"></div>
                      <div style="width: 65%">
                        <input class="form-control" id="phone" type="tel" placeholder="722001122" name="phone" />
                      </div>
                    </div>

                    <label>Pin (4 Digit)</label>
                    <soft-input id="password" type="password" placeholder="****" name="password" />
                    <div style="display:flow-root">
                      <soft-switch id="rememberMe" name="rememberMe" checked>
                        Remember me
                      </soft-switch>
                      <p class="mb-4 text-sm" style="margin-bottom: 0rem !important">
                        <router-link :to="{ name: 'Forgot Pin' }"
                          class="text-success text-gradient font-weight-bold">Forgot
                          Pin?</router-link>
                      </p>
                    </div>
                    <div class="text-center">
                      <router-link :to="{ name: 'Dashboard' }">
                        <soft-button class="my-4 mb-2" variant="gradient" color="success" full-width>Sign in
                        </soft-button>
                      </router-link>
                    </div>
                  </form>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link :to="{ name: 'Sign Up' }" class="text-success text-gradient font-weight-bold">Sign
                      up</router-link>
                  </p>
                </div> -->
                <div class="qrcode-body">
                  <div class="pb-0 text-qr">
                    <h3 style="font-size: 1.3rem;" class="font-weight-bolder text-success text-gradient">
                      Scan QR Code
                    </h3>
                    <img style="border-radius: 12px;" src="../assets/img/qr-code.png" class="navbar-brand-img h-100"
                      alt="qr_code" />
                    <p class="mb-0" style="padding: 5px">Or</p>
                    <soft-button class="mb-2" variant="gradient" color="success" full-width>Download App
                    </soft-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img/curved-images/curved5.png') +
                    ')',
                }"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "SignIn",
  components: {
    Navbar,
    AppFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
