<template>
  <!-- Navbar -->
  <nav class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
    :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'">
    <div class="container">
      <div class="sidenav-header-logo">
        <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
          aria-hidden="true" id="iconSidenav"></i>
        <a class="m-0 navbar-brand" href="/sign-in">
          <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
        </a>
      </div>
      <!-- <router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3" to="/dashboard" v-bind="$attrs"
        :class="isBlur ? 'text-dark' : 'text-white'">
        Sortika
      </router-link> -->
      <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation"
        aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon mt-2">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navigation">
        <ul class="navbar-nav mx-auto">
          <!-- <li class="nav-item">
            <router-link class="nav-link me-2" to="/sign-up">
              <i class="fas fa-user-circle opacity-6 me-1" aria-hidden="true"
                :class="isBlur ? 'text-dark' : 'text-white'"></i>
              Sign Up
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link me-2" to="/sign-in">
              <i class="fas fa-key opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
              Sign In
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link me-2" to="/profile">
              <i class="fa fa-bank opacity-6 me-1" aria-hidden="true" :class="isBlur ? 'text-dark' : 'text-white'"></i>
              Knowledge Base
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-lg-block d-none">
          <li class="nav-item">
            <a href="#" class="btn btn-sm btn-round mb-0 me-1"
              :class="isBlur ? 'bg-gradient-dark' : 'bg-gradient-success'">Get Support</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import logo from "@/assets/img/logo-ct.png";

export default {
  name: "navbar",
  data() {
    return {
      downArrWhite,
      downArrBlack,
      logo,
    };
  },
  props: {
    btnBackground: String,
    isBlur: String,
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    darkModes() {
      return {
        "text-dark": this.darkMode,
      };
    },
  },
};
</script>
