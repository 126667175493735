<template>
  <navbar btn-background="bg-gradient-primary" />
  <div class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{
    backgroundImage:
      'url(' + require('@/assets/img/curved-images/curved2.jpg') + ')',
  }">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
          <p class="text-white text-lead">
            Save. Lend. Borrow. Invest.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Register new account</h5>
          </div>
          <div class="card-body">
            <form role="form">
              <div class="mb-3">
                <label>Full Name</label>
                <soft-input id="name" type="text" placeholder="John Doe" aria-label="Full Name" />
              </div>
              <div class="mb-3">
                <label>Phone Number</label>
                <div style="display:flex">
                  <soft-input id="email" type="number" placeholder="+254" name="email" style="width: 25%" />
                  <div style="margin-left: 10px"></div><soft-input id="email" type="number" placeholder="722001122"
                    name="email" style="width: 75%" />
                </div>
              </div>
              <div class="mb-3">
                <label>Pin (4 digit)</label>
                <soft-input id="password" type="password" placeholder="****" aria-label="Pin (4 digit)" />
              </div>
              <div class="mb-3">
                <label>Confirm Pin</label>
                <soft-input id="password" type="password" placeholder="****" aria-label="Confirm Pin" />
              </div>
              <div class="mb-3">
                <label>Referral Code</label>
                <soft-input id="password" type="password" placeholder="AB1234" aria-label="Referral Code" />
              </div>
              <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light" checked>
                I agree the
                <a href="javascript:;" class="text-dark font-weight-bolder">Terms and Conditions</a>
              </soft-checkbox>

              <div class="text-center">
                <router-link :to="{ name: 'Personal Information' }">
                  <soft-button color="success" full-width variant="gradient" class="my-4 mb-2">Next</soft-button>
                </router-link>
              </div>
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <router-link :to="{ name: 'Sign In' }" class="text-dark font-weight-bolder">
                  Sign in
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    SoftInput,
    SoftCheckbox,
    SoftButton,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
