<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-lg-4">
                <fixed-goals-card />
            </div>
        </div>
    </div>
    <div style="margin-top: 3.5rem; margin-bottom: -1rem !important; margin-left: 1.5rem !important;">
        <h6>Recommendations</h6>
    </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import FixedGoalsCard from "../components/FixedGoalsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
    faHandPointer,
    faUsers,
    faCreditCard,
    faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
    name: "fixed-goals-tab",
    data() {
        return {
            iconBackground: "bg-gradient-success",
            faCreditCard,
            faScrewdriverWrench,
            faUsers,
            faHandPointer,
        };
    },
    components: {
        FixedGoalsCard,
    },
    mounted() {
        setTooltip();
    },
};
</script>
