<template>
    <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
        <div class="card-body p-3 pb-0 mb-0">
            <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            Treasury Bill - 364 Days
                        </h6>
                        <span class="text-xs"></span>
                    </div>
                    <div class="d-flex align-items-center text-sm">

                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Investment Class
                        </h6>
                        <span class="text-xs">Fixed Income</span>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Min. Amount
                        </h6>
                        <span class="text-xs">50,000.00</span>
                    </div>
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Min. Period
                        </h6>
                        <span class="text-xs">364 Day(s)</span>
                    </div>
                </li>
                <hr class="mt-0 horizontal dark" />
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <button class="btn btn-link text-dark text-sm mb-0 px-0" style="color: #78519e !important"
                            data-bs-toggle="modal" data-bs-target="#investNowModal">
                            <i class="fa fa-bank text-lg me-1" aria-hidden="true"></i>
                            Invest Now
                        </button>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                        <button class="btn btn-link text-dark text-sm mb-0 px-0" data-bs-toggle="modal"
                            data-bs-target="#investDetailsModal">
                            <i class="fa fa-info-circle text-lg me-1" aria-hidden="true"></i>
                            More Details
                        </button>
                    </div>

                    <!-- Invest Now Modal -->
                    <div class="modal fade" id="investNowModal" tabindex="-1" role="dialog"
                        aria-labelledby="investNowModalTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div style="border-bottom: none;" class="modal-header">
                                    <h5 class="modal-title" id="investNowModalLabel">Treasury Bill 364 Days</h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <label>Amount</label>
                                    <soft-input id="password" type="number" placeholder="KES" name="password" />
                                    <label>Period</label>
                                    <soft-input id="date" type="date" value="2024-02-30" name="date" />
                                </div>
                                <div style="border-top: none" class="modal-footer">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="success">
                                        Invest Now
                                    </soft-button>
                                    <soft-button class="my-4 mb-2" variant="gradient" color="dark"
                                        data-bs-dismiss="modal">
                                        Close
                                    </soft-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- More Details Modal -->
                    <div class="modal fade" id="investDetailsModal" tabindex="-1" role="dialog"
                        aria-labelledby="investDetailsModalTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div style="border-bottom: none;" class="modal-header">
                                    <h5 class="modal-title" id="investDetailsModalLabel">Treasury Bill 364 Days</h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Risk Level</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Intermidiate
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Investment Asset Class</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Fixed Income
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Promoter/ Fund Manager</span>
                        <soft-avatar class="me-3" :img="gok" alt="kal" border-radius="xl" shadow="regular" />
                        <h6 style="color: #03da92 !important" class="text-dark mb-1 font-weight-bold text-sm">
                            Government Of Kenya
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Minimum Amount.</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            KES 50,000.00
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Minimum Period</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            364 Days
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">10 Year Perfomance</span>
                    </div>
                </li>
                <div class="z-index-2">
                    <reports-bar-chart id="chart-bar" title="" description="" :chart="{
                        labels: [
                            '2014',
                            '2015',
                            '2016',
                            '2017',
                            '2018',
                            '2019',
                            '2020',
                            '2021',
                            '2022',
                        ],
                        datasets: {
                            label: 'Yield (%)',
                            data: [10, 22, 16, 32, 31, 19, 12, 17, 26],
                        },
                    }" />
                </div>
                <p>
                    <a style="text-decoration: underline;"
                        class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                        data-bs-toggle="collapse" href="#collapseInvestTerms" aria-expanded="false"
                        aria-controls="collapseInvestTerms">
                        Goal Description
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </a>
                </p>
                <div class="collapse" id="collapseInvestTerms">
                    <div class=" card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                        richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident.
                    </div>
                </div>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Disclaimer</span>
                        <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-sm">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                            richardson ad squid
                        </h6>
                    </div>
                </li>
        </div>
        <div style="border-top: none" class="modal-footer">
            <soft-button class="my-4 mb-2" variant="gradient" color="success">
                Invest Now
            </soft-button>
            <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                Close
            </soft-button>
        </div>
    </div>
    </div>
    </div>
    </li>
    </ul>
    </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import gok from "@/assets/img/gok.png";

export default {
    name: "InvestmentCard",
    components: {
        SoftButton,
        SoftAvatar,
        SoftInput,
        ReportsBarChart,
    },
    data() {
        return {
            gok,
        };
    },
};
</script>
