<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-lg-9">
        <div class="card-body p-3 pb-0 mb-0">
          <div class="list-group">
            <div class="border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-dark font-weight-bold">
                  Income Stats
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 mb-4">
            <div class="mt-4 row">
              <div>
                <div class="">
                  <gradient-line-chart id="chart-line" title="" description="" :chart="{
                    labels: [
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec',
                    ],
                    datasets: [
                      {
                        label: 'Income',
                        data: [50, 40, 300, 220, 500, 250, 400, 230, 700],
                      },
                    ],
                  }" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="row">
              <div class="col-md-6">
                <mini-statistics-card title="Total Agents" value="239" :percentage="{
                  value: '+505%',
                  color: 'text-success',
                }" direction-reverse />
              </div>
              <div class="col-md-6">
                <mini-statistics-card title="Total Earnings" value="KES 57,500" :percentage="{
                  value: '+505%',
                  color: 'text-success',
                }" direction-reverse />
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <agent-list-table />
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <resource-card />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import ResourceCard from "./components/ResourceCard.vue";
import AgentListTable from "./components/AgentListTable.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";

export default {
  name: "Dashboard",
  components: {
    MiniStatisticsCard,
    AgentListTable,
    ResourceCard,
    GradientLineChart,
  },
  mounted() {
    setTooltip();
  },
};
</script>
