<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navtips btn-background="bg-gradient-primary" />
            </div>
        </div>
    </div>
    <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
        <span class="mask bg-gradient-header opacity-6"></span>
        <div class="container">
            <div class="row justify-content-center">
                <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
                <div class="card z-index-0">
                    <div class="pb-0 card-header text-start">
                        <h3 class="font-weight-bolder text-success text-gradient">
                            Savings Explainer
                        </h3>
                        <p class="mb-0">Watch the video to learn more</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <div>
                                <iframe
                                    style="width: 100%; height: 12rem; object-fit: contain; margin-top: 1rem; margin-bottom: 1rem; border-radius: 12px;"
                                    src="https://www.youtube.com/embed/vw73jkYk5AM"
                                    title="How To Become Your Own Bank 2022" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                            <div style="margin-top: 10px; margin-bottom: 20px" class="row align-items-center">
                                <div>
                                    <nav-pill />
                                </div>
                            </div>
                            <div class="explainer-body">
                                <div class="pb-0 text-explainer">
                                    <p class="mb-0" style="padding: 5px">Fixed savings goals, provide a term limit
                                        to your savings to ensure you are able
                                        to save towards specific targets without
                                        liquidating your savings plan. You can
                                        only access your savings after the
                                        minimum period has been reached.
                                        This is highly recommended for savers
                                        who would like to cultivate a disciplined
                                        savings culture</p>
                                </div>
                            </div>
                            <div style="display:flex">
                                <router-link style="width: 50%" :to="{ name: 'Product' }">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="dark" full-width>Back
                                    </soft-button>
                                </router-link>
                                <div style="margin-left: 10px"></div>
                                <router-link style="width: 50%" :to="{ name: 'Create BYOB' }">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="success" full-width>Create
                                        a Goal
                                    </soft-button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
    name: "Savings Explainer",
    components: {
        Navtips,
        AppFooter,
        SoftButton,
        NavPill,
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
};
</script>
