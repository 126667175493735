<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <h6>Loan Requests</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Borrower
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Interest
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Amount
                            </th>
                            <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                Status
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Mike Foo</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">10%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 20,300.50</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="success" variant="gradient" size="sm">Approved</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Peter Njue</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">12%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 15,987.10</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="secondary" variant="gradient" size="sm">Pending</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Salim Ahmed</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">7%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 5,100.00</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="danger" variant="gradient" size="sm">Rejected</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Mary Wambo</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">9%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 2,000.00</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="success" variant="gradient" size="sm">Approved</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Salim Ahmed</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">7%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 5,100.00</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="danger" variant="gradient" size="sm">Rejected</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Peter Njue</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">12%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 15,987.10</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="secondary" variant="gradient" size="sm">Pending</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex px-2">
                                    <div>
                                    </div>
                                    <div class="my-auto">
                                        <h6 style="margin-left: 10px" class="mb-0 text-sm">Mike Foo</h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p class="text-sm font-weight-bold mb-0">10%</p>
                            </td>
                            <td>
                                <span class="text-xs font-weight-bold">KES 20,300.50</span>
                            </td>
                            <td class="align-middle text-center">
                                <div class="d-flex align-items-center justify-content-center">
                                    <soft-badge color="success" variant="gradient" size="sm">Approved</soft-badge>
                                </div>
                            </td>
                            <td class="align-middle">
                                <button class="btn btn-link text-secondary mb-0">
                                    <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// import SoftProgress from "@/components/SoftProgress";
import SoftBadge from "@/components/SoftBadge.vue";

export default {
    name: "lender-request-table",
    components: {
        SoftBadge
        // SoftProgress,
    },
};
</script>
