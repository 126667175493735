<template>
    <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
            <li class="nav-item">
                <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab"
                    href="../../../pages/investment/treasury-364.html" role="tab" aria-selected="true">Treasury Bill
                    364</a>
            </li>
            <li class="nav-item">
                <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="../../../pages/investment/britam.html"
                    role="tab" aria-selected="false">Britam</a>
            </li>
            <li class="nav-item">
                <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="../../../pages/investment/adam-jaza.html"
                    role="tab" aria-selected="false">Adam Jaza</a>
            </li>
        </ul>
    </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
    name: "nav-pill-invest",
    mounted() {
        setNavPills();
    },
};
</script>
