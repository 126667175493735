<template>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Withdrawal Payment Methods</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button color="dark" variant="gradient" data-bs-toggle="modal" data-bs-target="#exampleModalMessage">
            <i class="fas fa-plus me-2"></i>
            Add Payment Method
          </soft-button>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModalMessage" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalMessageTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div style="border-bottom: none;" class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Preferred Withdrawal Method</h5>
            <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="width:100%; height: 80px" class="radio-card">
              <input type="radio" id="radio3" name="radioDisabled" v-model="paymentOption" value="payment1">
              <!-- <label class="custom-control-label" for="customRadioDisabled">M-PESA</label> -->
              <img style="position: absolute; margin: 10px; margin-left: 20px" class="w-10 me-3 mb-0"
                src="@/assets/img/logos/mpesa.png" alt="logo" />
            </div>
            <div style="width:100%; height: 80px; margin-top: -1rem" class="radio-card">
              <input type="radio" id="radio3" name="radioDisabled" v-model="paymentOption" value="payment2">
              <!-- <label class="custom-control-label" for="customRadioDisabled">VISA Card</label> -->
              <img style="position: absolute; margin: 10px; margin-left: 15px" class="w-10 me-3 mb-0"
                src="@/assets/img/logos/bank.png" alt="logo" />
            </div>
            <form>
              <div v-if="paymentOption === 'payment1'" class="form-group">
                <label for="recipient-name" class="col-form-label">M-PESA Account Number:</label>
                <input type="tel" class="form-control" placeholder="0722 111 222" id="recipient-name">
              </div>
              <div v-if="paymentOption === 'payment2'" class="form-group">
                <label for="recipient-name" class="col-form-label">Bank Account Details:</label>
                <input type="text" class="form-control" placeholder="Bank Account Name" id="recipient-name">
                <input style="margin-top: 0.5rem" type="number" class="form-control" placeholder="Bank Account Number"
                  id="recipient-name">
                <input style="margin-top: 0.5rem" type="text" class="form-control" placeholder="Bank eg. Stanbic"
                  id="recipient-name">
                <input style="margin-top: 0.5rem" type="text" class="form-control" placeholder="Bank Branch"
                  id="recipient-name">
              </div>
            </form>
          </div>
          <div style="border-top: none" class="modal-footer">
            <soft-button style="margin-right: 0.1rem;" class="my-4 mb-2" variant="gradient" color="success">
              Add Payment Method
            </soft-button>
            <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
              Close
            </soft-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-6 mb-md-0 mb-4">
          <div class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
            <img class="w-10 me-3 mb-0" src="@/assets/img/logos/mpesa.png" alt="logo" />
            <h6 class="mb-0">
              254&nbsp;&nbsp;&nbsp;***&nbsp;&nbsp;&nbsp;***&nbsp;&nbsp;&nbsp;852
            </h6>
            <i class="fas fa-pencil-alt ms-auto text-dark cursor-pointer" data-bs-toggle="tooltip"
              data-bs-placement="top" title aria-hidden="true" data-bs-original-title="Edit Payment"
              aria-label="Edit Payment"></i>
            <span class="sr-only">Edit Payment</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
            <img class="w-10 me-3 mb-0" src="@/assets/img/logos/bank.png" alt="logo" />
            <h6 class="mb-0">
              ****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;5248
            </h6>
            <i class="fas fa-pencil-alt ms-auto text-dark cursor-pointer" data-bs-toggle="tooltip"
              data-bs-placement="top" title aria-hidden="true" data-bs-original-title="Edit Payment"
              aria-label="Edit Payment"></i>
            <span class="sr-only">Edit Payment</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import img1 from "@/assets/img/logos/mastercard.png";
import img2 from "@/assets/img/logos/visa.png";

export default {
  name: "PaymentMethodsCard",
  components: {
    SoftButton,
  },
  data() {
    return {
      paymentOption: 'payment1',
      img1,
      img2,
    };
  },
};
</script>
