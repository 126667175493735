<template>
    <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navtips btn-background="bg-gradient-primary" />
            </div>
        </div>
    </div>
    <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
        <span class="mask bg-gradient-header opacity-6"></span>
        <div class="container">
            <div class="row justify-content-center">
                <!-- <div class="mx-auto text-center col-lg-5">
            <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
            <p class="text-white text-lead">
              Save, Invest, Borrow & Lend.
            </p>
          </div> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
                <div class="card z-index-0">
                    <div class="pb-0 card-header text-start">
                        <h3 class="font-weight-bolder text-success text-gradient">
                            Create a BYOB
                        </h3>
                        <p class="mb-0"><strong>BYOB</strong> means Be Your Own Bank</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="explainer-body">
                                <div class="pb-0 text-explainer">
                                    <p class="mb-0" style="padding: 5px">The Be Your Own Bank goal is a product by
                                        Sortika that has embedded in it the
                                        principles of a bank. The goal here is that
                                        you get to treat your money just like the
                                        bank does, focusing on growing your cash
                                        flow. This goal allows you to lend on the
                                        Sortika platform, borrow from your savings
                                        and has a minimum saving of Kes 5,200</p>
                                </div>
                            </div>
                            <div style="display:flex">
                                <router-link style="width: 50%" :to="{ name: 'Dashboard' }">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="dark" full-width>Skip
                                    </soft-button>
                                </router-link>
                                <div style="margin-left: 10px"></div>
                                <router-link style="width: 50%" :to="{ name: 'BYOB Deposit' }">
                                    <soft-button class="my-4 mb-2" variant="gradient" color="success" full-width>Create
                                    </soft-button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer />
</template>

<script>
import Navtips from "@/examples/PageLayout/Navtips.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import NavPill from "./NavPill.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
    name: "Lending BYOB",
    components: {
        Navtips,
        AppFooter,
        SoftButton,
        // NavPill,
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
};
</script>
