<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Borrowing Limit" value="KES 7,500" :percentage="{
                    value: '+505%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Lending Limit" value="KES 10,000" :percentage="{
                    value: '+3%',
                    color: 'text-success',
                }" direction-reverse />
            </div>
            <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Passive Savings" value="9%" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Borrowing limit" value="KES 7,500" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div> -->
        </div>
        <!-- <div style="margin-top: 10px; margin-bottom: 20px" class="row align-items-center">
            <div class="col-lg-4 col-sm-8">
                <nav-pill-loan>
                </nav-pill-loan>
            </div>
        </div> -->
        <div style="margin-top: 10px" class="nav-wrapper position-relative end-0 col-lg-4 col-sm-8" id="nav">
            <ul class="nav nav-pills nav-fill p-1" role="tablist">
                <li class="nav-item">
                    <a class="nav-link mb-0 px-0 py-1 active active" data-bs-toggle="tab" id="borrow" href="#"
                        role="tab" aria-controls="Borrow" aria-selected="true" v-on:click="makeActive('borrowActive')">
                        Borrow
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mb-0 px-0 py-1" data-bs-toggle="tab" href="#" id="lend" role="tab"
                        aria-controls="Lend" v-on:click="makeActive('lendActive')" aria-selected="false">
                        Lend
                    </a>
                </li>
            </ul>
        </div>
        <div>
            <div style="margin-left: -1.2rem !important" id="borrow" v-show="isActiveTab('borrowActive')">
                <borrower />
            </div>
            <div style="margin-left: -1.2rem !important" id="lend" v-show="isActiveTab('lendActive')">
                <lender />
            </div>
        </div>
    </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import NavPillLoan from "./components/NavPillLoan.vue";
// import NavPillLoanStatus from "./components/NavPillLoanStatus.vue";
import setTooltip from "@/assets/js/tooltip.js";
// import LendersCard from "./components/LendersCard.vue";
import Borrower from "./loansdash/Borrower.vue";
import Lender from "./loansdash/Lender.vue";
// import AllGoalsCard from "./components/AllGoalsCard.vue";
// import ActiveLoansTable from "./components/ActiveLoansTable.vue";
// import LoanRequestTable from "./components/LoanRequestTable.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import {
    faHandPointer,
    faUsers,
    faCreditCard,
    faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
    name: "loans-default",
    data() {
        return {
            iconBackground: "bg-gradient-success",
            faCreditCard,
            faScrewdriverWrench,
            faUsers,
            faHandPointer,
            choice: 'borrowActive' // Borrow is chosen by default
        };
    },
    methods: {
        makeActive: function (val) {
            this.choice = val;
        },
        isActiveTab: function (val) {
            return this.choice === val;
        }
    },
    components: {
        MiniStatisticsCard,
        // NavPillLoan,
        // NavPillLoanStatus,
        // ActiveLoansTable,
        // LendersCard,
        // LoanRequestTable,
        Borrower,
        Lender,
    },
    mounted() {
        setTooltip();
    },
};
</script>
