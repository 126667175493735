<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Your balance" value="KES 23,000" :percentage="{
          value: '+505%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card title="Returns" value="10%" :percentage="{
          value: '+3%',
          color: 'text-success',
        }" direction-reverse />
      </div>
      <div class="col-xl-6 col-sm-6 mb-xl-0 mb-4">
        <div class="chart">
          <canvas id="doughnut-chart" class="chart-canvas" height="200px"></canvas>
        </div>
      </div>

      <!-- <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <mini-statistics-card title="Passive Savings" value="9%" :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" direction-reverse />
            </div>
            <div class="col-xl-3 col-sm-6 mb-xl-0">
                <mini-statistics-card title="Borrowing limit" value="KES 7,500" :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" direction-reverse />
            </div> -->
    </div>
    <div style="margin-top: 10px; margin-bottom: 20px" class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <nav-pill-invest />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Built by developers</p>
                  <h5 class="font-weight-bolder">Soft UI Dashboard</h5>
                  <p class="mb-5">
                    From colors, cards, typography to complex elements, you will
                    find the full documentation.
                  </p>
                  <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;">
                    Read More
                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-success border-radius-lg h-100">
                  <img src="../assets/img/shapes/waves-white.svg"
                    class="position-absolute h-100 w-50 top-0 d-lg-block d-none" alt="waves" />
                  <div class="position-relative d-flex align-items-center justify-content-center h-100">
                    <img class="w-100 position-relative z-index-2 pt-4"
                      src="../assets/img/illustrations/rocket-white.png" alt="rocket" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div class="overflow-hidden position-relative border-radius-lg bg-cover h-100" style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            ">
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Work with the rockets
                </h5>
                <p class="text-white mb-5">
                  Wealth creation is an evolutionarily recent positive-sum game.
                  It is all about who take the opportunity first.
                </p>
                <a class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto" href="javascript:;">
                  Read more
                  <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="mt-4 row">
      <div>

        <div class="card z-index-2">
          <gradient-line-chart id="chart-line" title="Treasury Bill 364 Chart" description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>6% Interest</span> Earned" :chart="{
        labels: [
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Treasury Bill 364',
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
          },
          //   {
          //       label: 'Treasury Bill 364 Two',
          //       data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
          //   },
        ],
      }" />
        </div>
      </div>
    </div>
    <!-- <div class="row my-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <projects-card />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list class="h-100" title="Orders overview" description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month">
          <timeline-item color="success" icon="bell-55" title="$2400 Design changes" date-time="22 DEC 7:20 PM" />
          <TimelineItem color="danger" icon="html5" title="New order #1832412" date-time="21 DEC 11 PM" />
          <TimelineItem color="info" icon="cart" title="Server payments for April" date-time="21 DEC 9:34 PM" />
          <TimelineItem color="warning" icon="credit-card" title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM" />
          <TimelineItem color="primary" icon="key-25" title="Unlock packages for development"
            date-time="18 DEC 4:54 AM" />
          <TimelineItem color="info" icon="check-bold" title="Notifications unread" date-time="15 DEC" />
        </timeline-list>
      </div>
    </div> -->
    <div style="margin-top: 2.2rem; margin-bottom: -1rem !important;">
      <h6>Recommended Investments</h6>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <investment-card />
      </div>
    </div>
    <div style="margin-top: 3.5rem; margin-bottom: -1rem !important;">
      <h6>Popular Investments</h6>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <investment-card />
      </div>
    </div>
  </div>
</template>
<script>
// @ts-ignore
// import * as CanvasJs from 'canvasjs'
// import CanvasJSChart from '@/assets/data/CanvasJSVueComponent.vue';
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import NavPillInvest from "./components/NavPillInvest.vue";
import setTooltip from "@/assets/js/tooltip.js";
import InvestmentCard from "./components/InvestmentCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "investments-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      options: {
        theme: "light2",
        animationEnabled: true,
        title: {
          text: "World Silicon Production"
        },
        subtitles: [{
          text: "in tonnes"
        }],
        data: [{
          type: "doughnut",
          startAngle: 90,
          indexLabel: "{label} {y}(#percent%)",
          yValueFormatString: "#,##0K",
          toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span>, {y} tonnes",
          dataPoints: [
            { label: "China", y: 4500, color: "#DE2910" },
            { label: "Russia", y: 600, color: "#0039A6" },
            { label: "India", y: 370, color: "#F79432" },
            { label: "United States Of America", y: 320, color: "#37366B" },
            { label: "Brazil", y: 210, color: "#F5D900" },
            { label: "Malaysia", y: 150, color: "#F7C600" },
            { label: "Others", y: 876, color: "#7a7677" }
          ]
        }]
      },
      styleOptions: {
        width: "100%",
        height: "360px"
      }
    };
  },
  components: {
    MiniStatisticsCard,
    NavPillInvest,
    InvestmentCard,
    GradientLineChart,
    // CanvasJSChart,
  },
  mounted() {
    setTooltip();
  },
};
</script>
