<template>
  <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              Treasury Bill - 364 Days
            </h6>
            <span class="text-xs"></span>
          </div>
          <div class="d-flex align-items-center text-sm">

          </div>
        </li>
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Investment Class
            </h6>
            <span class="text-xs">Fixed Income</span>
          </div>
          <div class="d-flex align-items-center text-sm">
          </div>
        </li>
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Min. Amount
            </h6>
            <span class="text-xs">50,000.00</span>
          </div>
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              Min. Period
            </h6>
            <span class="text-xs">364 Day(s)</span>
          </div>
        </li>
        <hr class="mt-0 horizontal dark" />
        <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
          <div class="d-flex flex-column">
            <button class="btn btn-link text-dark text-sm mb-0 px-0" style="color: #78519e !important">
              <i class="fa fa-bank text-lg me-1" aria-hidden="true"></i>
              Invest Now
            </button>
          </div>
          <div class="d-flex align-items-center text-sm">
            <button class="btn btn-link text-dark text-sm mb-0 px-0">
              <i class="fa fa-info-circle text-lg me-1" aria-hidden="true"></i>
              More Details
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "InvoiceCard",
  components: {
    // SoftButton,
  },
};
</script>
