import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SortikaUIDashboard from "./sortika-ui-dashboard";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SortikaUIDashboard);
appInstance.use(PerfectScrollbar, {
  watchOptions: true,
  options: {
    suppressScrollY: true,
  },
});
appInstance.mount("#app");
