<template>
  <navbaronboarding btn-background="bg-gradient-primary" />
  <div class="pt-5 m-3 page-header align-items-start pb-11 border-radius-lg">
    <span class="mask bg-gradient-header opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <!-- <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome to Sortika!</h1>
          <p class="text-white text-lead">
            Save, Invest, Borrow & Lend.
          </p>
        </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Personal Information</h5>
          </div>
          <div class="card-body">
            <form role="form">
              <div class="mb-3">
                <div>
                  <label>Gender</label>
                  <div style="width:100%; height: 80px" class="radio-card">
                    <input type="radio" name="flexRadioDefault" id="customRadio2">
                    <label style="position: absolute; margin: 20px; cursor: pointer;" for="customRadio2">Male</label>
                  </div>
                  <div style="width:100%; height: 80px; margin-top: -1rem" class="radio-card">
                    <input type="radio" name="flexRadioDefault" id="customRadio3">
                    <label style="position: absolute; margin: 20px; cursor: pointer;" for="customRadio3">Female</label>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label>Date of Birth</label>
                <div style="display:flex">
                  <soft-input id="email" type="number" placeholder="Day" name="email" style="width: 33%" />
                  <div style="margin-left: 10px"></div>
                  <soft-input id="email" type="number" placeholder="Month" name="email" style="width: 33%" />
                  <div style="margin-left: 10px"></div>
                  <soft-input id="email" type="number" placeholder="Year" name="email" style="width: 33%" />
                </div>
              </div>
              <div class="mb-3">
                <label>Your Address</label>
                <soft-input id="text" type="text" placeholder="Search..." aria-label="Location" />
                <!-- <bulma-dropdown options="options" @select="selected = $event" placeholder="Search" /> -->
              </div>
              <div class="text-center">
                <router-link :to="{ name: 'Quick Survey' }">
                  <soft-button color="success" full-width variant="gradient" class="my-4 mb-2">Next</soft-button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbaronboarding from "@/examples/PageLayout/Navbaronboarding.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
// import { BulmaDropdown } from 'vue-custom-google-autocomplete'

import { mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    Navbaronboarding,
    AppFooter,
    SoftInput,
    SoftButton,
    // BulmaDropdown,
  },
  // data() {
  //   return {
  //     selected: null,
  //     options: {
  //       apiKey: 'AIzaSyA_Gzs5l038qJy8Z722G6UQGxalcSJDkMU',//process.env.VUE_APP_PLACE_API_KEY,
  //       deepSearch: true,
  //       cors: true,
  //       focus: false,
  //       params: {
  //         location: '45.52345,-122.67621',
  //         radius: 1000,
  //         language: 'en'
  //       }
  //     },
  //   }
  // },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
