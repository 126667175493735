<template>
    <div>
        <div style="margin-top: 2rem" class="row">
            <div class="col-md-4 mb-4">
                <div class="card card-pricing">
                    <div class="card-header bg-gradient-dark text-center pt-4 pb-5 position-relative">
                        <div class="z-index-1 position-relative">
                            <h5 class="text-white">Launch Package</h5>
                            <h1 class="text-white mt-2 mb-0">
                                <small>Less than 25K</small>
                            </h1>
                            <h5 class="text-white">Total Annual Deposit</h5>
                            <h6 class="text-white">(Less than 12 months <br />Average goal target period)</h6>
                        </div>
                    </div>
                    <div class="position-relative mt-n5" style="height: 50px;">
                        <div class="position-absolute w-100">
                            <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40"
                                preserveAspectRatio="none" shape-rendering="auto">
                                <defs>
                                    <path id="card-wave"
                                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z">
                                    </path>
                                </defs>
                                <g class="moving-waves">
                                    <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                    <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                    <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                    <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                    <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                    <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="card-body text-center">
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Savings</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Create custom &amp; fixed savings goals</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Atleast <b>2</b> weekly SMS reminders on goals</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm"><b>3</b> M-PESA STK push for daily target completion</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Investments</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Access listed investment products</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Add <b>5</b> investment products to your portfolio</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Atleast <b>2</b> portfolio recommendations</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>P2P Loans</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Borrow <b>3</b> times a month</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Lend <b>5</b> times a month</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>PFM</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Create a budget</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Categorized incomes and expenses</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Group</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Join groups on Sortika</span>
                            </div>
                        </div>
                        <a href="javascript:;" class="btn bg-gradient-dark w-100 mt-4 mb-0">
                            Make A Deposit
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="card card-pricing">
                    <div class="card-header bg-gradient-dark text-center pt-4 pb-5 position-relative">
                        <div class="z-index-1 position-relative">
                            <h5 class="text-white">Premium Package</h5>
                            <h1 class="text-white mt-2 mb-0">
                                <small>25K to 100K</small>
                            </h1>
                            <h5 class="text-white">Total Annual Deposit</h5>
                            <h6 class="text-white">(Min. of 12 months <br />Average goal target period)</h6>
                        </div>
                    </div>
                    <div class="position-relative mt-n5" style="height: 50px;">
                        <div class="position-absolute w-100">
                            <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40"
                                preserveAspectRatio="none" shape-rendering="auto">
                                <defs>
                                    <path id="card-wave"
                                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z">
                                    </path>
                                </defs>
                                <g class="moving-waves">
                                    <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                    <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                    <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                    <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                    <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                    <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="card-body text-center">
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Savings</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Create custom &amp; fixed savings goals</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Atleast <b>2</b> weekly SMS reminders on goals</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm"><b>3</b> M-PESA STK push for daily target completion</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Investments</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Access listed investment products</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Add <b>5</b> investment products to your portfolio</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Atleast <b>2</b> portfolio recommendations</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>P2P Loans</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Borrow <b>3</b> times a month</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Lend <b>5</b> times a month</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>PFM</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Create a budget</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Categorized incomes and expenses</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Group</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Join groups on Sortika</span>
                            </div>
                        </div>
                        <a href="javascript:;" class="btn bg-gradient-dark w-100 mt-4 mb-0">
                            Make A Deposit
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="card card-pricing">
                    <div class="card-header bg-gradient-dark text-center pt-4 pb-5 position-relative">
                        <div class="z-index-1 position-relative">
                            <h5 class="text-white">Platinum Package</h5>
                            <h1 class="text-white mt-2 mb-0">
                                <small>100K and Above</small>
                            </h1>
                            <h5 class="text-white">Total Annual Deposit</h5>
                            <h6 class="text-white">(Min. of 18 months <br />Average goal target period)</h6>
                        </div>
                    </div>
                    <div class="position-relative mt-n5" style="height: 50px;">
                        <div class="position-absolute w-100">
                            <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40"
                                preserveAspectRatio="none" shape-rendering="auto">
                                <defs>
                                    <path id="card-wave"
                                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z">
                                    </path>
                                </defs>
                                <g class="moving-waves">
                                    <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                    <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                    <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                    <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                    <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                    <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="card-body text-center">
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Savings</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Create custom &amp; fixed savings goals</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Atleast <b>2</b> weekly SMS reminders on goals</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm"><b>3</b> M-PESA STK push for daily target completion</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Investments</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Access listed investment products</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Add <b>5</b> investment products to your portfolio</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Atleast <b>2</b> portfolio recommendations</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>P2P Loans</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Borrow <b>3</b> times a month</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Lend <b>5</b> times a month</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>PFM</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Create a budget</span>
                            </div>
                        </div>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Categorized incomes and expenses</span>
                            </div>
                        </div>
                        <ul class="list-unstyled max-width-200 mx-auto">
                            <li>
                                <strong>Group</strong>
                                <hr class="horizontal dark">
                            </li>
                        </ul>
                        <div class="d-flex pb-3">
                            <div>
                                <i class="fas fa-check text-success text-sm" aria-hidden="true"></i>
                            </div>
                            <div class="ps-3">
                                <span class="text-sm">Join groups on Sortika</span>
                            </div>
                        </div>
                        <a href="javascript:;" class="btn bg-gradient-dark w-100 mt-4 mb-0">
                            Make A Deposit
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";

export default {
    name: "packages-default",
    mounted() {
        setTooltip();
    },
};
</script>