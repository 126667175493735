<template>
    <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
        <div class="card-body p-3 pb-0 mb-0">
            <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h5 class="mb-1 text-dark font-weight-bold">
                            Samsung Galaxy S23 Ultra
                        </h5>
                        <span class="text-xs"></span>
                    </div>
                    <div class="d-flex align-items-center text-sm">

                    </div>
                </li>
                <div class="col-md-12">
                    <div style="padding: 2rem !important;"
                        class="card-header p-0 mx-3 mt-3 position-relative z-index-1">
                        <img src="../../assets/img/logos/samsung.png" style="height: 200px"
                            class="img-fluid border-radius-lg">
                    </div>

                </div>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-sm">Current Prize</span>
                        <h6 style="text-decoration: line-through;" class="text-dark mb-1 font-weight-bold">
                            KES 112,000
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-sm">Sale Prize</span>
                        <h6 style="color: #0db17a !important" class="text-dark mb-1 font-weight-bold">
                            KES 97,000
                        </h6>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-sm">Description</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            The samsung galaxy s23 ultra with 200X Zoom launched recently with tghe Quulcomm 3nm 2nd Gen
                            chip with 12GB of RAM and 256GB internal base storage
                        </h6>
                    </div>
                </li>
                <hr class="mt-0 horizontal dark" />
                <div>
                    <soft-button style="box-shadow:none" variant="gradient" color="success" fullWidth
                        data-bs-toggle="modal" data-bs-target="#premiumProductDetailsModal">Add Goal
                    </soft-button>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="premiumProductDetailsModal" tabindex="-1" role="dialog"
                    aria-labelledby="premiumProductDetailsModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div style="border-bottom: none;" class="modal-header">
                                <h5 class="modal-title" id="premiumProductDetailsModalLabel">Premium Goal</h5>
                                <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group">
                                        <input class="form-control" type="date" value="2023-11-23" id="premium-date">
                                    </div>
                                </form>
                                <p>
                                    <a style="text-decoration: underline;"
                                        class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                                        data-bs-toggle="collapse" href="#collapseGoalTerms" aria-expanded="false"
                                        aria-controls="collapseGoalTerms">
                                        Goal Terms
                                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                    </a>
                                </p>
                                <div class="collapse" id="collapseGoalTerms">
                                    <div class="card card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                        richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes
                                        anderson cred nesciunt sapiente ea proident.
                                    </div>
                                </div>
                            </div>
                            <div style="border-top: none" class="modal-footer">
                                <soft-button style="margin-right: 0.1rem;" class="my-4 mb-2" variant="gradient"
                                    color="success">
                                    Add Goal
                                </soft-button>
                                <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                                    Close
                                </soft-button>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
// import SoftProgress from "@/components/SoftProgress";

export default {
    name: "PremiumProductCard",
    components: {
        // SoftProgress,
        SoftButton,
    },
};
</script>
