<template>
    <div class=" pb-0 card-header">
        <h6 class="mb-0">Select all the goals you want to withdraw from plus the amount</h6>
    </div>
    <div class="py-4 container-fluid">
        <ul class="list-group">
            <li class="px-0 border-0 list-group-item">
                <div>
                    <div style="display:flex; justify-content: space-between; margin-bottom: 0.5rem;">
                        <div class="form-check">
                            <input type="checkbox" id="radio3" name="radioDisabled" class="form-check-input">
                            <label class="custom-control-label" for="customRadioDisabled">Client
                                Goal One</label>
                        </div>
                        <input style="width: 40%" class="form-control form-control-sm" type="number"
                            placeholder="KES 5000">
                    </div>
                    <div style="display:flex; justify-content: space-between; margin-bottom: 0.5rem;">
                        <div class="form-check">
                            <input type="checkbox" id="radio3" name="radioDisabled" class="form-check-input">
                            <label class="custom-control-label" for="customRadioDisabled">Client
                                Goal Two</label>
                        </div>
                        <input style="width: 40%" class="form-control form-control-sm" type="number"
                            placeholder="KES 2000">
                    </div>
                    <div style="display:flex; justify-content: space-between; margin-bottom: 0.5rem;">
                        <div class="form-check">
                            <input type="checkbox" id="radio3" name="radioDisabled" class="form-check-input">
                            <label class="custom-control-label" for="customRadioDisabled">Client
                                Goal Three</label>
                        </div>
                        <input style="width: 40%" class="form-control form-control-sm" type="number"
                            placeholder="KES 1000">
                    </div>
                    <div style="align-items: flex-end; margin-top: 2rem" class="d-flex flex-column">
                        <span style="font-size: 0.9rem;">Total Withdrawable</span>
                        <h4 class="text-dark mb-1 font-weight-bold">
                            KES 6,000
                        </h4>
                    </div>
                </div>
                <div style="margin-bottom: 1rem;"></div>
            </li>
        </ul>
    </div>

</template>

<script>
// import SoftInput from "@/components/SoftInput.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
// import SoftButton from "@/components/SoftButton.vue";

export default {
    name: "select-goal",
    components: {
        // SoftInput,
        // SoftCheckbox,
        // SoftButton,
    },
};
</script>
