<template>
    <div class="card h-100 mt-4" style="padding-left: 10px; padding-right: 10px">
        <div class="card-body p-3 pb-0 mb-0">
            <ul class="list-group">
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            Custom Goal
                        </h6>
                        <span class="text-xs"></span>
                    </div>
                    <div class="d-flex align-items-center text-sm">

                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Savings Progress
                        </h6>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                    </div>
                </li>
                <div style="margin-bottom: 1rem;">
                    <soft-progress color="success" variant="gradient" :percentage="50" />
                </div>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">Allocation</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            100%
                        </h6>
                    </div>
                    <div class="d-flex flex-column">
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            Saved
                        </h6>
                        <span class="text-xs">1,000.00 of 5,200.00</span>
                    </div>
                </li>
                <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                    <div class="d-flex flex-column">
                        <span class="text-xs">End Date</span>
                        <h6 style="color: red !important" class="text-dark mb-1 font-weight-bold text-sm">
                            03-06-2023
                        </h6>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="text-xs">Remaining Day(s)</span>
                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                            162 of 190 Day(s)
                        </h6>
                    </div>
                </li>
                <hr class="mt-0 horizontal dark" />
                <div>
                    <soft-button style="box-shadow:none" variant="gradient" color="success" fullWidth
                        data-bs-toggle="modal" data-bs-target="#customGoalDetailsModal">More
                        Details
                    </soft-button>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="customGoalDetailsModal" tabindex="-1" role="dialog"
                    aria-labelledby="customGoalDetailsModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div style="border-bottom: none;" class="modal-header">
                                <h5 class="modal-title" id="customGoalDetailsModalLabel">Custom Goal</h5>
                                <button type="button" class="btn-close text-dark" data-bs-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">Daily Target</span>
                                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                                            200.00
                                        </h6>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">Saved Amount</span>
                                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                                            1,000.00
                                        </h6>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">Interest Earned</span>
                                        <h6 style="color: #03da92 !important"
                                            class="text-dark mb-1 font-weight-bold text-sm">
                                            5%
                                        </h6>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">Target Amount</span>
                                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                                            5,200.00
                                        </h6>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">Allocation</span>
                                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                                            100%
                                        </h6>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">Remaining Day(s)</span>
                                        <h6 class="text-dark mb-1 font-weight-bold text-sm">
                                            72 of 190 Day(s)
                                        </h6>
                                    </div>
                                </li>
                                <li
                                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                    <div class="d-flex flex-column">
                                        <span class="text-xs">End Date</span>
                                        <h6 style="color: red !important"
                                            class="text-dark mb-1 font-weight-bold text-sm">
                                            03 June 2023
                                        </h6>
                                    </div>
                                </li>
                                <p>
                                    <a style="text-decoration: underline;"
                                        class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                                        data-bs-toggle="collapse" href="#collapseGoalTerms" aria-expanded="false"
                                        aria-controls="collapseGoalTerms">
                                        Goal Terms
                                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                    </a>
                                </p>
                                <div class="collapse" id="collapseGoalTerms">
                                    <div class="card card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                        richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes
                                        anderson cred nesciunt sapiente ea proident.
                                    </div>
                                </div>
                            </div>
                            <div style="border-top: none" class="modal-footer">
                                <soft-button style="margin-right: 0.1rem;" class="my-4 mb-2" variant="gradient"
                                    color="success">
                                    Edit Goal
                                </soft-button>
                                <soft-button class="my-4 mb-2" variant="gradient" color="dark" data-bs-dismiss="modal">
                                    Close
                                </soft-button>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftProgress from "@/components/SoftProgress";

export default {
    name: "CustomGoalsCard",
    components: {
        SoftProgress,
        SoftButton,
    },
};
</script>
